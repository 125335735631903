<template>
    <div class="col-12">
        <template v-if="ikajoDataError || notEnoughData">
            <!--Error-->
            <error></error>
            <p v-if="notEnoughData" class="alert alert-danger mt-5 text-center">{{$t('not-enough-data')}}</p>
            <p v-if="ikajoDataError" class="alert alert-danger mt-5 text-center">{{ikajoDataError}} {{$t('request-unsuccessful')}}</p>
        </template>

        <div v-else-if="ikajoData">
            <!--Success-->
            <form :action="ikajoData.providerUrl" method="POST" ref="ikajoForm">
                <input type="hidden" name="order" :value="ikajoData.order">
                <input type="hidden" name="payment" :value="ikajoData.payment">
                <input type="hidden" name="key" :value="ikajoData.key">
                <input type="hidden" name="url" :value="ikajoData.successRedirectUrl">
                <input type="hidden" name="data" :value="ikajoData.data">
                <input type="hidden" name="error_url" :value="ikajoData.failedRedirectUrl">
                <input type="hidden" name="sign" :value="ikajoData.sign">
            </form>
        </div>
    </div>
</template>

<script>
    import {getIkajoData} from "../../../services/backend.service";

    export default {
        // This page is accessed with a transaction ID. A call is made with that transaction ID to get Ikajo deposit data.
        // With that data we fill a hidden form on the page and submit it automatically.
        name: 'Ikajo',
        data(){
            return{
                notEnoughData: null,
                ikajoDataError: null,
                ikajoData: null,
            }
        },
        components:{
            Error: () => import('../../others/Error'),
        },
        mounted(){
            const transactionId = this.$router.history.current.query.transactionid;
            if(transactionId){
                getIkajoData(transactionId).then(res => {
                    if(res.data.success && res.data.data){
                        this.ikajoData = res.data.data;
                    } else {
                        this.ikajoDataError = res.data.message && res.data.message !== "No message available" ? res.data.message : this.$t('error-2');
                    }
                }).catch( e => {
                    this.ikajoDataError = this.$t('error-2');
                    console.log(`%c Error in IkajoDeposit.vue - method: getIkajoData - ${e}`, 'color:red');
                });
            } else {
                this.notEnoughData = true;
            }
        },
        updated(){
            if(this.ikajoData){
                this.submitForm();
            }
        },
        methods:{
            submitForm(){
                this.$refs.ikajoForm.submit();
            }
        }
    }
</script>

<style scoped></style>
